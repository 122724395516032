import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { styledPayStatus } from '../styled/styledPayStatus';

const NotFoundPage = ({ className }) => (
  <Layout className={className}>
    <SEO title='404: Not found' />
    <div className='message'>
      <span>Oops</span>
      <span>You just hit a route that doesn&#39;t exist...</span>
      <span>the sadness.</span>
    </div>
  </Layout>
)

export default styledPayStatus(NotFoundPage);
